<template>
  <section class="main">
    <h5>404; 该页面未查找到, {{time/1000}}秒即将跳转到首页</h5>
  </section>
</template>

<script>
export default {
  data () {
    return {
      time: 3000
    }
  },
  created () {
    setTimeout(() => {
      location.href = "/"
    }, this.time)
  },
  methods: {

  }
}
</script>

<style scoped>
.main{display:flex;align-items:center;justify-content:center;min-height:45vh;font-size:3rem;}
</style>